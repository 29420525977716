* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@media (min-width: 1000px) {
	.header {
		width: 98%;
		height: 8rem;
		margin: 0.5% auto;
		padding: 1%;
		border-radius: 100px;
		background-color: #f29ca4;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.pencil_title_box {
		flex: 60%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-left: 1%;
	}

	.pencilBox {
		width: 5rem;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logout_box {
		width: 5.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.pencil {
		width: 1rem;
		height: 5rem;
		margin: 2%;
	}

	.sign-in {
		width: 70%;
		text-align-last: left;
		font-size: 60px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #fff;
		margin-left: 1.5%;
	}

	.off_button {
		width: 4rem;
		height: 4rem;
		align-self: center;
		justify-content: center;
		position: relative;
	}

	.share_button {
		width: 6rem;
		height: 6rem;
		align-self: center;
		justify-content: center;
		position: relative;
	}
}

@media (max-width: 999px) and (min-width: 350px) {
	.header {
		width: 98%;
		height: 8rem;
		margin: 0.5% auto;
		padding: 1%;
		border-radius: 100px;
		background-color: #f29ca4;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.pencil_title_box {
		flex: 60%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-left: 1%;
	}

	.pencilBox {
		width: 5rem;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logout_box {
		width: 5.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.pencil {
		width: 1rem;
		height: 5rem;
		margin: 2%;
	}

	.sign-in {
		width: 50%;
		text-align-last: left;
		font-size: 6vw;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #fff;
		margin-left: 2%;
	}

	.off_button {
		width: 4rem;
		height: 4rem;
		align-self: center;
		justify-content: center;
		position: relative;
	}
}
