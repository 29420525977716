#big-input{
    display: block;
    width: 70%;
    height: 2rem;
    margin-top: 1%;
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #f29ca4;
    align-self: flex-start;
}
.BIG.SignUpInput-container{
    width: 70%;
    height: 4rem;
    margin-top: 7%;
    margin-left: 5%;
}

.first.SignUpInput-container{
    margin-top: 20%;
}

.SignUpInput-container{
    width: 90%;
    height: 2.7rem;
    padding: 3%;

    margin: 1.2% auto;

    opacity: 0.6;
    border-radius: 22px;
    border: none;
    background-color: #f4e9eb;
    color: black;

    font-size: 25px;
}

::placeholder {
    color: black;
    opacity: 1; /* Firefox */
}
