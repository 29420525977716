* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container{
    width: 100%;
    height: 39rem;

    margin: 1% auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.col1{
    display: flex;
    position: relative;
    width: 30%;
    height: 100%;
}

.flexCol1{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#inputImages{
    display: none;

}

.circlePlusButton{
    position: absolute;
    top: 10%;
    left: 25%;
    cursor: pointer;

}

.plusButton{
    position: absolute;
    top: 19%;
    left:40%;
    cursor: pointer;
}

.col2{
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.col3{
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    justify-content: center;
    position: relative;
    left: 5%;
}

.checkbox-label{
    font-size: 1rem;
    text-align: left;
    margin: 3%;
    color: #f29ca4;
    cursor: auto;
}

#signUpCheckboxNewsletter{
    position: relative;

}

#signUpCheckboxPrivacy{
    position: relative;

}

.linkPrivacy{
    text-decoration: underline;
    color: #f29ca4;
}


.wrapperCheckbox{
    margin-left: 5%;
}


.submit-border{
    width: 70%;
    height: 6rem;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    border-radius: 39px;
    border: solid 10px #f29ca4;
    background-color: #fff;
    position: relative;
    z-index: 1;
    box-shadow: 15px 15px  #f8f2f3;
    margin-top: 2%;
}

.imageUploaded{
    background-size: cover;
    background-repeat: no-repeat;
    border:solid 25px #f29ca4;    
    height:245px;
    width:245px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    position: absolute;
    top: 10%;
    left: 25%;

}
