.mainBorder {
	width: 98%;
	height: 100%;

	padding: 2% 1% 2%;
	margin: 1%;

	border-radius: 140px;
	border: solid 40px #f29ca4;
	/* background-color: #f29ca4; */
}

.congratsImage {
	width: 80%;
	height: 30%;
	margin: 1% 10% 4%;
}

#congratsParagraph {
	width: 90%;
	height: 30%;
	margin: 1% 1% 2% 4.5%;

	font-size: 3.5rem;

	text-align: center;
	justify-content: center;

	position: relative;
}

.congratsInooker {
	font-size: 3.5rem;
	color: #f29ca4;
}

#congratsParagraph_2 {
	position: relative;
	bottom: 20px;
}

#congratsParagraph_3 {
	width: 90%;
	height: 40%;
	margin: 1.5% 1% 2% 4.5%;

	font-size: 2.5rem;
	font-weight: 300;
	text-align: center;
	justify-content: center;

	position: relative;
}

.congratsRedirecting {
	display: flex;
	justify-content: space-between;
}

.congratsButton {
	width: 30%;
	height: 4rem;
	text-align: center;
	box-shadow: 6px 5px 14px grey;
	margin: 2% 5% 2% 10%;

	font-size: 2rem;
	font-weight: 600;
	border-radius: 39px;
	border: solid 10px #f29ca4;
	background-color: #fff;
	position: relative;
	z-index: 1;
}

.congratButtonShadow {
	width: 30%;
	height: 4rem;

	border-radius: 28px;
	background-color: #f8f2f3;
	position: relative;

	top: 38px;
	right: 360px;

	z-index: 0;
}

.congratsWishes {
	width: 33%;
	height: 2rem;
	text-align: center;

	margin: 3% 5% 2% 5%;

	font-size: 1.5rem;
	font-weight: 400;

	position: relative;
}

.congratsInook {
	color: #f29ca4;
}
