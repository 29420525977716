.loaderBackground{
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.1); */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loader{
    width: 250px;
    height: 250px;
}