.newBookWrapper {
	display: flex;
	flex-direction: row;
	height: 39rem;
}

.modalChapter {
	outline: 0;
}
.newBookFirstCol {
	flex: 28;
	height: 100%;

	margin: 1% 2% 1%;
	padding: 2% 1%;
}
.imageBook {
	width: 250px;
	height: 150px;
	background-image: "cover";
}

.flexColNewBook {
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: solid 22px #f29ca4;
	cursor: pointer;
}

.newBookSecondCol {
	flex: 68;
	height: 100%;

	margin: 1% 1% 1%;
	padding: 2%;
	display: flex;
	flex-direction: column;
}

.newBookParagraph {
	flex: 10;
	margin-left: 4%;
	margin-bottom: 0;
	font-size: 2rem;
	font-weight: 500;
}

.newBookForm {
	flex: 90;

	display: flex;
	flex-direction: column;
}

.newBookTitle {
	flex: 5;
	width: 100%;
	border-radius: 47px;
	padding: 2%;
	margin: 3% 0%;
	text-align: center;
	font-size: 2.5rem;
	font-weight: 700;
	border-color: transparent;
	background-color: #f4e9eb;
	outline-color: #f29ca4;
}

.newBookTitle::placeholder {
	color: grey;
}

.newBookTitle:focus::placeholder {
	color: transparent;
}

.newBookDescription {
	flex: 15;
	padding: 3%;
	border-radius: 47px;
	border-color: transparent;
	background-color: #f4e9eb;
	outline-color: #f29ca4;
	resize: none;
}

.newBookDescription::placeholder {
	color: grey;
}

.newBookDescription::-webkit-scrollbar {
	display: none;
}

.newBookDescription:focus::placeholder {
	color: transparent;
}

.newBookSubmissionWrapper {
	flex: 10;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-content: "center";
	position: relative;
}

.categoriesList {
	width: 60%;
	height: 70%;
	margin-top: 2%;
	margin-left: 4%;
	padding: 1.3%;
	border-radius: 26px;
	text-align: center;
	border-color: transparent;
	background-color: #f4e9eb;
	outline-color: #f29ca4;
}

.newBookSubmit {
	width: 30%;
	margin-top: 10;
	height: 70%;
	margin-right: 4%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.3%;

	font-size: 2rem;
	font-weight: 600;
	box-shadow: 10px 10px #f8f2f3;

	border-radius: 47px;
	border: solid 12.9px #f29ca4;
	background-color: #fff;
}
