.privacyWrapper{
    width: 98%;
    height: 78vh;
    margin: 1% auto;
    padding: 3% 1%;
    border-radius: 100px;
    border: solid 40px #f29ca4;
    background-color: #fff;

    position: relative;
    display: flex;
    flex-direction: row;
}

.privacyBody{
    flex: 70%;
    height: 98%;
    
    padding: 0% 3%;
    justify-content: space-between;

    text-align: left;
    font-size: 1.4rem;
    overflow-y:scroll;
}

.privacyButtonWrapper{
    flex: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}

.privacyButton{
    width: 70%;
    height: 6rem;
    font-size: 2vw;
    font-weight: 500;
    border-radius: 39px;
    border: solid 15px #f29ca4;
    background-color: #fff;
    align-self: center;
    position: relative;
    bottom: 10px;
    right: 25px;
    box-shadow: 15px 15px  #f8f2f3;
}