.WrapperWriter {
	display: flex;
	flex-direction: row;
	height: 97.5vh;
	width: 97.5vw;
	margin: 0.6%;
}

.colWrapperWriter {
	display: flex;
	flex-direction: column;
}

.col1 {
	flex: 78;
}

.sizeWrapperWriter {
	width: 94%;
	margin: 1% 3%;
}

.ql-container.ql-snow {
	border: none !important;
}
.ql-toolbar.ql-snow {
	border: none !important;
}
.sizeWrapperWriter.title {
	flex: 7;

	border-radius: 25px;
	border: solid 5px #f29ca4;
	background-color: #fff;

	padding: 0.2% 2%;

	font-size: 2rem;
	font-weight: bold;
	color: #f29ca4;
}

.sizeWrapperWriter.title::placeholder {
	color: #f29ca4;
}

.sizeWrapperWriter.title:focus::placeholder {
	color: transparent;
	outline: none;
}

.sizeWrapperWriter.desc {
	flex: 7;
	display: flex;
	flex-direction: row;

	justify-content: space-between;
}

.descriptionWrapperWriter {
	width: 90%;
	border-radius: 25px;
	border: solid 5px #f29ca4;
	background-color: #fff;

	padding: 0.2% 2%;
	font-weight: 300;
	font-size: 2rem;
}

.descriptionWrapperWriter::placeholder {
	color: black;
}

.descriptionWrapperWriter:focus::placeholder {
	color: transparent;
	outline: none;
}

input:focus {
	outline: none;
}

textarea:focus {
	outline: none;
}

.numberChapterWrapperWriter {
	width: 8%;
	border-radius: 50px;
	border: solid 5px #f29ca4;
	background-color: #f29ca4;

	text-align: center;
	font-weight: 500;
	font-size: 2rem;
}
.react-confirm-alert-overlay {
	z-index: 9999;
	background-color: #fff;
	opacity: 0.5;
	background: rgba(255, 255, 255, 0.1) !important;
}

.numberChapterWrapperWriter:hover + .hidedDescriptionWrapper {
	display: block;
	position: absolute;
	z-index: 9999;
	left: 88%;
	top: 9%;
	width: 20%;
}

.hidedDescriptionWrapper {
	display: none;
}

.sizeWrapperWriter.editor {
	flex: 80;

	border-radius: 25px;
	border: solid 5px #f29ca4;
	background-color: #fff;

	padding: 1% 2%;
}

.sizeWrapperWriter.editor:focus::placeholder {
	color: transparent;
	outline: none;
}

.col2 {
	flex: 28;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.buttonsWriter {
	display: flex;
	flex-direction: column;

	width: 80%;
	margin: 3%;
}

#buttonHomeWriter {
	border-radius: 25px;
	background-color: #f8f2f3;

	height: 3rem;
	font-size: 2rem;
	border: none;

	margin: 2%;
}

#buttonSpaceWriter {
	border-radius: 25px;
	background-color: #f8f2f3;

	height: 3rem;
	font-size: 2rem;
	border: none;

	margin: 2%;
}

#buttonWriterDraft {
	border-radius: 25px;
	border: solid 8px #f29ca4;
	background-color: #fff;

	height: 4rem;
	font-size: 1.8rem;
	font-weight: bold;

	margin: 1%;
}

#buttonWriterPublish {
	border-radius: 25px;
	border: solid 8px #f29ca4;
	background-color: #fff;

	height: 4rem;
	font-size: 1.8rem;
	font-weight: bold;

	margin: 1%;
}

.pageChoicer {
	display: flex;
	flex-direction: row;

	justify-content: center;
}

.pageButton {
	border-radius: 10px;
	border: solid 2px #f29ca4;
	background-color: #fff;
	width: 20px;
	margin: 3px;
}
