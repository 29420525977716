.modalBackground {
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.1); */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.modalContainer {
    width: 500px;
    /* height: 400px; */
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 30px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}
  
.modalContainer .modalTitle {
    display:flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 5px;
}
  
.titleCloseBtn {
    display: flex;
    background-color: white;
    border: none;
    font-size: 25px;
    cursor: pointer;
}
  
.modalContainer .modalBody {
    flex: 90%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 1.4rem;
    text-align: left;
    overflow-y: auto;

    
      
    ::-webkit-scrollbar {
        width: 16px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #f29ca4;
        border: 4px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;  
    }

}


  
.modalContainer .modalFooter {
    flex: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.modalContainer .modalFooter button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: #f29ca4;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}
  
#cancelBtn {
    background-color: crimson;
}