.mainBorder {
	width: 98vw;
	height: 96vh;

	padding: 2% 1% 2%;
	margin: 1%;

	border-radius: 140px;
	border: solid 40px #f29ca4;
	/* background-color: #f29ca4; */
}

.resetParagraph {
	width: 90%;
	height: 40%;

	font-size: 3.5rem;

	text-align: center;
	justify-content: center;

	position: relative;
}

.resetParagraph_1 {
	font-size: 3.5rem;
	font-weight: 300;
	margin-top: 2%;
	margin-bottom: 4%;
}

.reset-code-label,
.reset-password-label {
	width: 30%;
	height: 25px;

	display: inline-block;

	font-size: 33px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.88;
	letter-spacing: normal;
	text-align: justify;
	color: #372d2d;

	margin: 0.5% 1% 3% 25%;
}

.reset-code-input,
.reset-password-input {
	width: 25%;
	height: 1.5rem;
	padding: 2%;

	opacity: 0.6;
	border-radius: 22px;
	border: none;
	background-color: #f4e9eb;

	font-size: 25px;
}

.resetRedirecting {
	display: flex;
	justify-content: center;
}

.resetButton {
	width: 30%;
	height: 4rem;
	text-align: center;

	margin: 2% 5% 2% 5%;

	font-size: 2rem;
	font-weight: 600;
	border-radius: 39px;
	border: solid 10px #f29ca4;
	background-color: #fff;
	position: relative;

	left: 270px;

	z-index: 1;
}

.resetButtonShadow {
	display: flex;
	width: 30%;
	height: 4rem;

	border-radius: 28px;
	background-color: #f8f2f3;
	position: relative;
	margin: 2% 5% 2% 5%;
	top: 15px;
	right: 230px;

	z-index: 0;
}

.eyeStylePasswordReset {
	position: relative;
	right: 3%;
	bottom: 4px;
}
