* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navMenu-container{
    flex: 40% 1;
    display: flex;
    flex-direction: column;
    height: 80vh;
    margin: 1% 1%;
    border-radius: 140px;
    border: solid 30px #f7c8cf;
    background-color: #fff;
    overflow: auto;
    justify-content: center;
}
