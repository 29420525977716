.wrapperDraftSave{
    flex: 60%;
    margin: 1% 1%;
    border-radius: 140px;
    border: solid 30px #f7c8cf;
    background-color: #fff;

    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;
}

.writeSaveDraftTitle{
    margin-top: 10%;
    margin-bottom: 2%;
    font-size: 3rem;
    text-align: center;
}

.saveDraftForm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin-left: 10%;
    position: relative;
}

.titleSaveDraft{
    font-size: 2.8rem;
    position: relative;
    left: 3%;

}

.inputSaveDraft{
    width: 90%;
    height: 4rem;
    font-size: 2rem;
    font-weight: 500;
    padding: 2%;
    border-radius: 40px;
    border: #f29ca4;
    background-color: #f8f2f3;
    letter-spacing: normal;
    align-self: flex-start;
}

.buttonSaveDraft{
    flex: 1;
    width: 30%;
    height: 6rem;
    text-align: center;
    align-self: flex-end;
    font-size: 3rem;
    font-weight: 600;
    border-radius: 60px;
    border: solid 10px #f29ca4;
    background-color: #fff;

    box-shadow: 15px 15px  #f8f2f3;
    margin-top: 5%;

    position: relative;
    right: 20%;
}