* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.containerProfile{
    width: 100%;
    height: 39rem;

    margin: 1% auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.col1Profile{
    display: flex;
    position: relative;
    width: 30%;
    height: 100%;
}

.flexCol1Profile{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#inputImagesProfile{
    display: none;

}

.circlePlusButtonProfile{
    position: absolute;
    top: 10%;
    left: 25%;
    cursor: pointer;

}

.plusButtonProfile{
    position: absolute;
    top: 19%;
    left:39%;
    cursor: pointer;
}

.col2Profile{
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.col3Profile{
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    justify-content: flex-end;
    position: relative;
    left: 5%;
}

.checkbox-label{
    font-size: 1rem;
    text-align: left;
    margin: 3%;
    color: #f29ca4;
    cursor: auto;
}

#signUpCheckboxNewsletter{
    position: relative;

}

#signUpCheckboxPrivacy{
    position: relative;

}

.linkPrivacy{
    text-decoration: underline;
    color: #f29ca4;
}


.wrapperCheckbox{
    margin-left: 5%;
}


.submit-borderProfile{
    width: 70%;
    height: 6rem;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    border-radius: 39px;
    border: solid 10px #f29ca4;
    background-color: #fff;
    position: relative;
    bottom: 10vh;
    z-index: 1;
    box-shadow: 15px 15px  #f8f2f3;
    margin-top: 2%;
}

.imageUploadedProfile{
    background-size: cover;
    background-repeat: no-repeat;
    border:solid 25px #f29ca4;    
    height:245px;
    width:245px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    position: absolute;
    top: 10%;
    left: 25%;
    cursor: pointer;

}

#big-inputProfile{
    display: block;
    width: 70%;
    height: 2rem;
    margin-top: 1%;
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #f29ca4;
    align-self: flex-start;
}
.BIG.SignUpInput-containerProfile{
    width: 70%;
    height: 4rem;
    margin-top: 7%;
    margin-left: 5%;
}

.first.SignUpInput-containerProfile{
    margin-top: 15%;
}

.SignUpInput-containerProfile{
    width: 90%;
    height: 2.7rem;
    padding: 3%;

    margin: 1.2% auto;

    opacity: 0.6;
    border-radius: 22px;
    border: none;
    background-color: #f4e9eb;
    color: black;

    font-size: 25px;
}

::placeholder {
    color: black;
    opacity: 1; /* Firefox */
}

.inputPasswordContainerProfile{
    display: flex;
    align-items: center;
    width: 100%;
}

::placeholder {
    color: black;
    opacity: 1; /* Firefox */
}

.eyeStyleSignUp1 {

    width: 2%;
    color: #f29ca4;
    position: absolute;
    right: 37.3vw;
    z-index: 3;
    cursor: pointer;
}

.eyeStyleSignUp2 {
    width: 2%;
    color: #f29ca4;
    position: absolute;
    right: 37.3vw;
    z-index: 3;
    cursor: pointer;
}