.mainBorder{
    width: 98%;
    height: 100%;

    padding: 2% 1% 2%;
    margin: 1%;

    border-radius: 140px;
    border: solid 40px #f29ca4;
    /* background-color: #f29ca4; */
}

#deniedParagraph{
    width: 90%;
    height: 40%;
    margin: 1% 1% 2% 4.5%;

    font-size: 3.5rem;

    text-align: center;
    justify-content: center;

    position: relative;
}

#deniedParagraph_1{
    margin: 3% ;
}

#deniedParagraph_2{
    width: 90%;
    height: 25%;
    margin: 5.5% 1% 0% 4.5%;

    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
    justify-content: center;

    position: relative;
}

.deniedRedirecting{
    display: flex;
    justify-content: center;
}

.deniedButton{

    width: 30%;
    height: 4rem;
    text-align: center;

    margin: 2% 5% 2% 5%;

    font-size: 2rem;
    font-weight: 600;
    border-radius: 39px;
    border: solid 10px #f29ca4;
    background-color: #fff;
    position: relative;


    left: 270px;

    z-index: 1;

}

.deniedButtonShadow{
    display: flex;
    width: 30%;
    height: 4rem;

    border-radius: 28px;
    background-color: #f8f2f3;
    position: relative;
    margin: 2% 5% 2% 5%;
    top: 15px;
    right: 230px;


    z-index: 0;
}

