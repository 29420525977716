.main_Border{
    width: 98%;
    height: 100%;

    padding: 2% 1% 2%;
    margin: 1%;

    border-radius: 140px;
    border: solid 40px #f29ca4;
    /* background-color: #f29ca4; */
}

.reset_Paragraph{
    width: 90%;
    height: 40%;
    margin: 1% 1% 10% 4.5%;

    font-size: 3.5rem;

    text-align: center;
    justify-content: center;

    position: relative;
}

.reset_Paragraph_1{
    font-size: 3.5rem;
    font-weight: 300;
    margin-top: 2%;
    margin-bottom: 8%;
}

.reset_mail_label{
    width: 100%;
    height: 20%;
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #372d2d;

    margin-bottom: 9%;


}

.reset_mail_label_text{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-right: 3%;
    line-height: 1;
}

.reset_mail_input{
    width: 40%;
    height: 50px;
    padding: 2%;
    display: inline-block;
    opacity: 0.6;
    border-radius: 22px;
    border: none;
    background-color: #f4e9eb;
    justify-content: center;
    position: relative;

    margin-left: 3%;
    font-size: 2.2rem;
}



.reset_Redirecting{
    display: flex;
    justify-content: center;
}

.reset_Button{

    width: 30%;
    height: 4rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 39px;
    border: solid 10px #f29ca4;
    background-color: #fff;
    position: relative;
    bottom: 35px;
    box-shadow: 15px 15px  #f8f2f3;
}

.reset_ButtonShadow{
    display: flex;
    width: 30%;
    height: 4rem;

    border-radius: 28px;
    background-color: #f8f2f3;
    position: relative;
    margin: 2% 5% 2% 5%;
    top: -15px;
    right: 190px;


    z-index: 0;
}