.arched-text {
	font-size: 24px;
	position: relative;
	width: 300px;
	height: 100px;
	text-align: center;
	transform: rotate(-10deg) skewX(-20deg);
}

.arched-text span {
	display: inline-block;
	transform: skewX(20deg) rotate(10deg);
}
