.dropdown-container {
	text-align: left;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 35%;
	height: 70%;
	margin-top: 2%;
	margin-left: 0%;
	padding: 1.3%;

	font-size: 1.2rem;

	border-radius: 26px;
	border-color: transparent;
	background-color: #f4e9eb;
	outline-color: #f29ca4;
	cursor: pointer;
}

.dropdown-input {
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	user-select: none;
}

.dropdown-menu {
	display: none;
	flex-direction: column;
	position: absolute;
	transform: translateY(4px);
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 26px;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 200px;
	bottom: 80px;
	right: 0px;
	background-color: #f4e9eb;
}

.dropdown-menu::-webkit-scrollbar {
	display: none;
}

.showMenu {
	display: flex;
}

.dropdown-item {
	width: 90%;
	padding: 7px;
	margin-left: 10px;
	margin-right: 3px;
	cursor: pointer;
}

.dropdown-item.selected {
	background-color: #f29ca4;
	border-radius: 26px;
	color: #fff;
}

.dropdown-item:hover {
	background-color: #f29ca4;
	border-radius: 26px;
}

#categoryInput {
	display: none;
	cursor: pointer;
}
