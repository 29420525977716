
.baseWrapper{
    display: flex;
    flex-direction: row;
}

.choicesWrapper{
    flex: 60%;
    margin: 1% 1%;
    border-radius: 140px;
    border: solid 30px #f7c8cf;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.homeInookerWrapper{
    flex: 40%;
    height: 30%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

}

.homeTitleInooker{
    font-size: 3.6vw;
    font-weight: bold;
    margin-top: 6vh;
    margin-bottom: 0;
}

.homeButtonsIconsWrapper{
    display: flex;
    flex-direction: column;
}

.homeButtonsWrapper{
    flex: 65%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.homeWriteButton{
    flex: 50%;
    width: 42vw;
    height: 30%;
    margin: 5%;

    border-radius: 39px;
    border: solid 10px #f7c8cf;
    background-color: #fff;
    
    line-height: 0;
    font-size: 3vw;
    font-weight: bold;
    
    color: #372d2d;
    box-shadow: 15px 15px  #f8f2f3;
    position: relative;
}

.homeReadButton{
    flex: 50%;

    height: 30%;
    margin: 5%;

    border-radius: 39px;
    border: solid 10px #f7c8cf;
    background-color: #fff;
    
    line-height: 0;
    font-size: 3vw;
    font-weight: bold;
    
    color: #372d2d;
    box-shadow: 15px 15px  #f8f2f3;
    position: relative;
}

.homeWriteButton:hover, .homeReadButton:hover{
    color: #f7c8cf;
}

.homePencilIcon{
    width: 12vw;
    height: 12vh;
    position: absolute;
    right: 2px;
    top: 25px;
}

.homeBookIcon{
    width: 8vw;
    height: 12vh;
    position: absolute;
    top: 23px;
    right: -33px;
}