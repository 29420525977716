#free_color{
    color: black;
    text-decoration: none;
}

div.rdw-editor-main{
    height: 62vh;
    overflow-y: scroll;
}

div.rdw-editor-main::-webkit-scrollbar{
    display: none;
}

a {
    text-decoration: none;
}