* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.button{
    width: 23vw;

    align-self: center;
    text-align: center;
    margin: 3vh;

    border-radius: 39px;
    border: solid 10px #f7c8cf;
    background-color: #fff;

    font-size: 3vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    color: #372d2d;

}

.link{
    color: black;
    text-decoration: none;
}

.link:hover{
    color:#f29ca4;
}