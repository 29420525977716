.choicesWrapper {
	flex: 60%;
	height: 80vh;
	margin: 1% 1%;
	border-radius: 140px;
	border: solid 30px #f7c8cf;
	background-color: #fff;

	display: flex;
	flex-direction: column;

	align-items: center;
	overflow: auto;
	position: relative;
}

.choicesWrapper {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.choicesWrapper::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.choiseNotExist {
	font-size: 2.7rem;
	font-weight: 600;
	text-align: center;
	position: relative;
	top: 10rem;
}

.choiseWrite {
	width: 40vw;
	align-self: center;
	text-align: center;
	margin-left: "50px !important";
	margin-bottom: "50px !important";
	margin: 1.2vh;
	border-radius: 39px;
	border: solid 10px #f7c8cf;
	background-color: #fff;
	font-size: 3vw;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	color: #372d2d;
}
