.baseWrapper {
	display: flex;
	flex-direction: row;
}

.choicesWrapperWallet {
	height: 80vh;
	border-radius: 140px;
	border: solid 30px #f7c8cf;
	background-color: #fff;

	display: flex;
	flex-direction: row;

	justify-content: space-evenly;
	align-items: center;
}
.coinCounterWrapper {
	font-weight: 700;
	font-size: 3.3rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 40vh;
}

.table-container-wallet {
	flex: 60%;
	justify-content: space-around;
	height: 100vh;
	font-size: large;
	margin: 7%;
	overflow-y: auto;
	border-radius: 3px;
	max-height: 30px;
}

.scroll-thead {
	text-align: center;
	background-color: #f7c8cf;
}

/* .scroll-thead th{
    border-radius: 40%;
} */

.scroll-container {
	border-top: 1px solid #ccc;
	text-align: center;
	max-height: 200px;
	overflow-y: scroll;
}

.scroll-container::-webkit-scrollbar {
	display: none;
}

.scroll-container td:first-child {
	border-right: 1px solid #ccc;
}
.scroll-container td {
	border-bottom: 1px solid #ccc;
}

tr {
	width: 100px;
	height: 50px;
	padding: 0;
}
td {
	border: 1px solid #ccc;
	padding: 2px;
}

.WalletCentered {
	text-align: center;
}
