
.baseWrapper{
    display: flex;
    flex-direction: row;
}

.choicesWrapperYourSpace{
    flex: 60%;
    margin: 1% 1%;
    border-radius: 140px;
    border: solid 30px #f7c8cf;
    background-color: #fff;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.buttonWriteRedirecting{
    width: 40vw;
    align-self: center;
    text-align: center;
    margin: 2vh;
    border-radius: 39px;
    border: solid 10px #f7c8cf;
    background-color: #fff;
    font-size: 3vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #372d2d;
}

