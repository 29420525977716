* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* background-color: linear-gradient(top right, #FFFFFF 0%, #CC88FF 100%); */
}

.root {
	width: 100%;
	height: 100%;
}

.container-fluid {
	width: 100%;
	height: 100%;
	padding: 10px 0px 10px 0px;
}

/* LEFT COLOMN */

#planet {
	width: 93%;
	height: 67%;
	margin: 25% 3% 2% 4%;
}

ul {
	display: block;
	list-style: none;
	justify-content: space-between;
}

/* li:first-child {
	display: inline-block;
	text-align: right;
	margin-left: 28%;
	margin-right: 5%;
} */

ul li {
	display: inline-block;
	text-align: center;
	margin-right: 5%;
	margin-top: 1%;
}

li:last-child {
	display: inline-block;
	text-align: left;
}

li a {
	text-decoration: none;
	color: #f29ca4;
}

/* RIGHT COLOMN */

#inook {
	width: 62%;
	height: 100%;

	margin: 0 20%;
}

.rettangolo-grande {
	width: 100%;
	height: 80%;

	margin: 2% auto;

	border-radius: 140px;
	border: solid 40px #f29ca4;
	background-color: #fff;
}

.rettangolo-login {
	width: 300px;
	height: 120px;

	margin: 5% 24%;

	border-radius: 10px;
	border: solid 25px #f29ca4;
	background-color: #fff;
}

.login {
	margin: 7% 9%;
	font-size: 50px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.77;
	letter-spacing: 5px;
	text-align: justify;
	color: #372d2d;
	background-color: #fff;
}

.username-label,
.password-label {
	width: 150px;
	height: 25px;

	display: inline-block;

	font-size: 33px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.88;
	letter-spacing: normal;
	text-align: justify;
	color: #372d2d;

	margin: 4% 7% 3% 10%;
}

.username-input {
	width: 274px;
	height: 44px;
	padding: 3%;

	opacity: 0.6;
	border-radius: 22px;
	border: none;
	background-color: #f4e9eb;

	font-size: 25px;
}

.user-input {
	background-color: #f4e9eb;
	border: "0px";
}

.credenziali {
	width: 200px;
	display: inline-block;
	margin: 3% 35% 3% 32%;
}

#loginCheckbox {
	width: 15px;
	height: 15px;
	display: inline;
	margin-left: 5%;
	border-radius: 50%;
}

input .checkbox:checked {
	background-color: #f29ca4;
}

.accedi-button {
	width: 200px;
	height: 56px;
	margin: 0% 33% 0% 32%;
	border-radius: 28px;
	border: solid 5px #f29ca4;
	background-color: #fff;

	font-size: 33px;
}

.invito-registrazione {
	width: 300px;
	height: 30px;
	display: inline-block;
	margin: 6% 21% 2% 26%;
}

.invito-recupero-psw {
	width: 400px;
	height: 30px;
	display: inline-block;
	margin: 0% 14% 2% 16%;
}

.link {
	text-decoration: none;
	color: #f29ca4;
}

.eyeStyleLogin {
	color: #f29ca4;
	position: relative;
	right: 6.5%;
	bottom: 5px;
	z-index: 3;
	cursor: pointer;
}

.login-container {
	height: 100vh;
}
.login-form {
	width: 50%;
	height: 100vh;
	background-color: #f39ea6;
	border-radius: 160px;
	border: 30px solid #f39ea6;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-left: 20px;
	padding: 40px;
}

.left-grid {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
}

.left-grid-background {
	width: 100%;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(
		90deg,
		rgba(207, 139, 228, 1) 0%,
		rgba(255, 255, 255, 1) 100%,
		rgba(0, 212, 255, 1) 100%
	);
	height: 100%;
}

.social-links-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 70%;
	margin-top: -50px;
}

.right-grid {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
}

.form-container {
	display: flex;
	flex-direction: column;
	justify-content: "";
	margin-top: 20px;
	align-items: center;
	height: 100%;
	border: 30px solid #f39ea6;
	border-radius: 160px;
	width: 70%;
	max-width: 800px;
	min-width: 600px;
	margin-bottom: 50px;
}

.form-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 20px solid #f39ea6;
	margin-top: 20px;
	border-radius: 10px;
	padding: 0px 20px;
}

.form-header-title {
	font-size: 58px;
	font-weight: 600;
}

.form-input-section {
	width: 100%;
	padding: 16px;
}

.submit-button {
	border: 5px solid #f39ea6;
	color: #000;
	font-size: 25px;
	border-radius: 28px;
	text-transform: none;
	padding: 0px 23px 0px 24px;
	font-weight: 600;
	height: auto;
}

.link-section {
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
}
